//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid';/* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
@import 'buttons';
@import 'forms';
@import 'slick';
@import 'slick-theme';
@import 'fancybox';
@import 'tabs';

//
//!!SITE WIDE STYLE
//

//
//!!STRUCTURE START
//

.matchHeight{
	margin-bottom: 0;
}

.lazy-loader{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: white;
	z-index: 9999;

	&.loaded{
		opacity: 0;
		display: none;
	}
}

.main-wrapper{
	padding-top: 0;
	padding-bottom: 0px;
	width: 100%;
}

.page-wrapper{
	margin: 0 auto;
	padding: 0;
	width: 100%;
	position: relative;
	transition: .25s;
	height: auto;
	padding-top: 0;
	bottom: 0;
}

//
//!!STRUCTURE END
//

//
//!!SIMPLE BASE STYLES START
//

.full-bg{
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

//
//!!SIMPLE BASE STYLES END
//

//
//!! BREADCRUM START
//

.breadcrumb-con{
	@include font-size(18);
	line-height: 1.2em;
	margin-bottom: 50px;
	color: $colour;
	font-weight: 400;
	display: flex;
	justify-content: flex-start;

	@media (max-width: $b3){
		margin-bottom: 30px;
	}

	span{
		color: white;
		@include font-size(18);
		line-height: 1.2em;
		display: inline-block;

		@media (max-width: $b3){
			@include font-size(12);
		}
	}

	a{
		@include font-size(18);
		letter-spacing: .015em;
		line-height: 1.2em;
		text-decoration: none;
		@include inline;
		vertical-align: middle;
		color: $orange;
		font-weight: 400;
		margin-right: .2em;
		position: relative;
		display: inline-block;

		@media (max-width: $b3){
			@include font-size(12);
		}

		&:after{
			content: '/';
			display: inline-block;
			margin-left: .4em;
			color: $colour;
		}


		&:hover,
		&:focus{
			color: $orange;
			text-decoration: none;
		}
	}

	> a{
		margin-left: 0;
	}

	.breadcrumb_last{
		@include font-size(18);
		letter-spacing: .015em;
		line-height: 1.2em;
		color: $colour;
		text-decoration: none;
		@include inline;
		vertical-align: middle;
		font-weight: 400;
		position: relative;

		@media (max-width: $b3){
			@include font-size(12);
		}
	}
}

//
//!! BREADCRUM END
//

//.pagination-simple{
//	padding: 7px 0 2px;
//
//	a{
//		color: $orange;
//		font-weight: 700;
//		padding-top: 1px;
//		@include inline;
//		@include font-size(20);
//		@include line-height(20,33);
//		letter-spacing: .03em;
//		margin-bottom: 1em;
//		text-transform: uppercase;
//
//		@media (max-width: $b2){
//			@include font-size(20 / $divider);
//		}
//
//		&:hover,
//		&:focus{
//			color: $orange;
//		}
//
//		&.back-to,
//		&[rel="next"]{
//			float: left;
//		}
//
//		&[rel="prev"]{
//			float: right;
//		}
//	}
//}

//simple pagination

//complex pagination

//.pagination-con{
//	display: flex;
//	justify-content: space-between;
//
//	@media (max-width: $b2){
//		justify-content: center;
//		flex-direction: column;
//		align-items: center;
//
//		.pagination.pagination.pagination{
//			margin-bottom: 20px;
//		}
//	}
//}
//
//.pagination-under{
//
//	.col-12{
//		display: flex;
//		justify-content: center;
//		align-items: center;
//
//		.pagination.pagination{
//			margin-bottom: 50px;
//		}
//	}
//}
//

.pagination.pagination.pagination{
	margin-bottom: 0;
	vertical-align: middle;
	display: flex;
	justify-content: center;
	width: 100%;
	align-items: flex-start;
	flex: 0 0 100%;
	max-width: 100%;
	padding-top: 20px;

	@media (max-width: $b3){
		margin-top: 0;
		margin-bottom: 50px;
	}

	.pagination-mid{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	div{
		text-align: center;
		margin: 0 12px;
		@include font-size(30);
		line-height: 20px;
		width: 77px;
		height: 77px;
		line-height: 77px;
		position: relative;
		z-index: 1;

		@media (max-width: $b2){
			margin: 0 6px;
			width: 30px;
			height: 30px;
			line-height: 30px;
		}

		a{

			&:after{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) rotate(45deg);
				width: 54.45px;
				min-width: 54.45px;
				height: 54.45px;
				background-color: white;
				border: 1px solid $grey;
				z-index: -1;
				transition: $time;

				@media (max-width: $b3){
					width: 25px;
					min-width: 25px;
					height: 25px;
				}
			}
		}

		&:not(.prev):not(.next){

			&.active{
				pointer-events: none;

				a{
					color: $white;

					&:after{
						background-color: $grey;
						width: 77px;
						min-width: 77px;
						height: 77px;


						@media (max-width: $b2){
							width: 32px;
							min-width: 32px;
							height: 32px;
						}
					}
				}
			}

			a{
				@include font-size(30);
				line-height: 77px;
				display: block;
				color: $colour;
				font-weight: 700;
				padding: 0;
				min-width: 20px;


				@media (max-width: $b2){
					line-height: 30px;
					@include font-size(16);
				}

				&:hover,
				&:focus{
					text-decoration: none;
					color: $white;

					&:after{
						background-color: $grey;
					}
				}
			}

			&.ellipse{
				color: $colour;
				font-weight: 700;
			}
		}

		&:first-of-type{
			margin-left: 0;
		}

		&:last-of-type{
			margin-right: 0;
		}

		&.next,
		&.prev{

			a{
				@include font-size(0);
				line-height: 1.2em;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $colour;
				text-decoration: none;
				background-position: 50% 37%;
				background-repeat: no-repeat;
				width: 77px;
				height: 77px;

				@media (max-width: $b2){
					width: 30px;
					height: 30px;
					line-height: 30px;
					background-size: 8px auto;
				}

				&:hover,
				&:focus{
					text-decoration: none;

					&:after{
						background-color: $grey;
					}
				}
			}
		}

		&.next{
			margin-left: 20px;

			@media (max-width: $b2){
				margin-left: 12px;
			}
			
			a{
				background-image: url(../images/arrow-right-p-grey.svg);
				background-position: 52% 50%;

				&:hover,
				&:focus{
					background-image: url(../images/arrow-right-p-white.svg);
				}
			}
		}

		&.prev{
			margin-right: 20px;

			@media (max-width: $b2){
				margin-right: 12px;
			}
			
			a{
				background-image: url(../images/arrow-left-p-grey.svg);
				background-position: 48% 50%;

				&:hover,
				&:focus{
					background-image: url(../images/arrow-left-p-white.svg);
				}
			}
		}
	}
}

//
//!!SOCIAL MENU START
//
$social-size:		16;
$social-addition:	0;

$facebook:		#3b5999;
$twitter:		#55acee;
$linkedin:		#007bb5;
$pinterest:		#cb2027;
$rss:			#ff6600;
$slideshare:	#f7941e;
$google-plus:	#dd4b39;
$instagram:		#8a3ab9;
$reddit:		#ff4500;
$youtube:		#bb0000;
$mail:			#767676;
$behance:		#131418;

.social-menu{
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	a{
		@include font-size($social-size);
		line-height: $social-size + $social-addition + px;
		height:  $social-size + $social-addition + px;
		min-width: $social-size + $social-addition + px;
		display: block;
		text-align: center;
		color: $grey;
		text-decoration: none;
		background-image: none;
		padding: 0;
		font-weight: 100;
		margin: 0 6px;
		background-color: transparent;

		&:first-of-type{
			margin-left: 0;
		}

		&:last-of-type{
			margin-right: 0;
		}

		@media (max-width: $b3){
			margin: 0 5px;
			@include font-size(18);

			i{
				@include font-size(18);

				&:before{
					@include font-size(18);
				}
			}
		}

		i{
			@include font-size($social-size);
			line-height: $social-size + $social-addition + px;
			min-width: $social-size + $social-addition + px;
			display: block;
			transition: $time;
		}

		&.facebook{

			&:hover,
			&:focus{
				color: $facebook!important;
			}
		}
//
//
//		&.behance{
//
//			&:hover,
//			&:focus{
//				color: $behance!important;
//			}
//		}
//
		&.twitter{

			&:hover,
			&:focus{
				color: $twitter!important;
			}
		}

		&.linkedin{

			&:hover,
			&:focus{
				color: $linkedin!important;
			}
		}
//
//		&.pinterest{
//
//			&:hover,
//			&:focus{
//				color: $pinterest!important;
//			}
//		}
//
//		&.rss{
//
//			&:hover,
//			&:focus{
//				color: $rss!important;
//			}
//		}
//
//		&.slideshare{
//
//			&:hover,
//			&:focus{
//				color: $slideshare!important;
//			}
//		}
//
//		&.google-plus{
//
//			&:hover,
//			&:focus{
//				color: $google-plus!important;
//			}
//		}
//
		&.instagram{

			&:hover,
			&:focus{

				i{
					color: $instagram!important;
				}
			}
		}
//
//		&.reddit{
//
//			&:hover,
//			&:focus{
//				color: $reddit!important;
//			}
//		}
//
//		&.youtube{
//
//			&:hover,
//			&:focus{
//				color: $youtube!important;
//			}
//		}
//
//		&.mail{
//
//			&:hover,
//			&:focus{
//				color: $mail!important;
//			}
//		}
	}
}

//
//!!SOCIAL MENU END
//

.waypoint{
	transition: .5s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(50px);
	will-change: transform;

	body.ie &{
		opacity: 1;
		visibility: visible;
		transition: 0s;
	}

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}

.hero-section{
	position: relative;
	background-position: 50% 85%;

	&:after{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba($grey-darker, .4);
		z-index: 1;
	}

	.container{
		position: relative;
		z-index: 2;
	}
	
	.row{
		padding-top: 30px;
		padding-bottom: 30px;
		min-height: 513px;
		align-items: center;
		text-align: center;

		@media (max-width: $b2){
			min-height: 400px;
		}

		@media (max-width: $b3){
			min-height: 300px;
		}
	}

	*{
		color: white;
	}

	.text{

		*:last-child{
			margin-bottom: 0;
		}
	}

	.image{

		img{
			margin: 3.2em auto 0;
		}
	}
}

.image-hero-section{

	.row{
		min-height: 513px;

		@media (max-width: $b2){
			min-height: 400px;
		}

		@media (max-width: $b3){
			min-height: 300px;
		}
	}
}

.intro-and-ranges-section{
	padding-top: 46px;

	&.no-ranges{
		padding-top: 70px;
		padding-bottom: 51px;
	}

	.text{
		text-align: center;
		padding-bottom: 10px;
	}

	.row{
		justify-content: center;

		&.bottom{
			margin-bottom: -9.1rem;
			position: relative;
			z-index: 2;
			background-color: white;
			padding-left: 9.5px;
			padding-right: 9.5px;

			@media (max-width: $b3){
				margin-bottom: 0;
			}
		}
	}

	.title{
		text-align: center;
		padding-bottom: 26px;
	}

	.col-lg-3{
		text-align: center;
		margin-bottom: 9.5px + 9.5px;

		.inner{
			position: relative;

			a{
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 2;
			}
		}

		h4{
			margin: 0;
			color: white;
		}

		img{
			width: 100%;
		}

		.title{
			padding: 10px 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 4.6em;
			transition: $time;
		}

		&.orange{

			&:hover,
			&:focus{
				
				.title{
					background-color: darken($orange, 10%);
				}
			}

			.title{
				background-color: $orange;
			}
		}

		&.red{

			&:hover,
			&:focus{
				
				.title{
					background-color: darken($red, 10%);
				}
			}
			
			.title{
				background-color: $red;
			}
		}

		&.brown{

			&:hover,
			&:focus{
				
				.title{
					background-color: darken($brown, 10%);
				}
			}
			
			.title{
				background-color: $brown;
			}
		}
	}
}

.simple-hero-section + .comparison-section{
	padding-top: 0;
	padding-bottom: 75px;
}

.intro-and-ranges-section.no-ranges + .comparison-section{
	padding-top: 85px;
	padding-bottom: 17px;

	@media (max-width: $b3){
		padding-top: 46px;
	}

	p{
		margin-bottom: 1.42em;
	}

	.text{

		@media (min-width: $b3 + 1){
			
			p{
				width: 630px;
			}
		}
	}
}

.comparison-section{
	background-image: url(../images/wave-back.svg);
	background-repeat: no-repeat;
	padding-top: 160px;
	padding-bottom: 81px;

	@media (max-width: $b3){
		padding-top: 46px;
		padding-bottom: 46px;
	}

	*{
		color: white;
	}

	.text{
		padding-top: 9px;

		@media (max-width: $b3){
			padding-top: 0px;
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 60%;
			max-width: 60%;

			p{
				width: 650px;
			}
		}

		h3{
			margin-bottom: .5em;
		}
	}

	.image{

		img{
			margin: 0 auto;
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 40%;
			max-width: 40%;
		}
	}

	.points{
		padding-top: 2.2em;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@media (min-width: $b2 + 1){
			padding-right: 40px;
		}

		.point{
			width: calc(33.3% - 9.5px - 9.5px);
			margin-bottom: 9.5px;

			@media (max-width: $b2){
				width: calc(50% - 9.5px - 9.5px);
				flex-grow: 1;
			}

			@media (max-width: $b3){
				@media (max-width: $b2){
					width: 100%;
					flex-grow: 1;
				}
			}
		}

		p{
			line-height: 1.29em;
		}

	}
}

.products-section{
	background-color: $grey-lighter;
	padding-top: 40px;
	padding-bottom: 26px;

	.title-con{
		text-align: center;
	}

	.container-fluid{
		padding: 0;
	}

	.row{
		justify-content: center;
	}

	h3{
		margin-bottom: .74em;
	}
}

.products-intro-section{
	background-color: $grey-lighter;
	padding-top: 98px;
	padding-bottom: 46px;
	text-align: center;

	@media (max-width: $b3){
		padding-top: 46px;
		padding-bottom: 0;
	}

	.row{
		justify-content: center;
	}
}

.pagination_row{

	.col-12{
		display: flex;
		justify-content: center;
	}
}

.pagination_products{
	padding-top: 6px;
	@include font-size(20);
	text-transform: uppercase;
	font-family: $alt-font;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $grey-dark;

	.page-numbers{
		margin: 0 5px;
		color: $grey-dark;
		line-height: 26px;
		min-width: 26px;
		min-height: 26px;
		text-align: center;
		border-radius: 3.329px;

		&:hover,
		&:focus,
		&.current{
			background-color: $orange;
		}
	}

	.next{
		background-image: url(../images/slide-next.svg);
		
		span{
			display: none;
		}
	}

	.prev{
		background-image: url(../images/slide-prev.svg);

		span{
			display: none;
		}
	}

	.prev,
	.next{
		background-repeat: no-repeat;
		min-width: 26px;
		min-height: 26px;
		background-position: 50% 50%;
	}
}

.yith-wcan-filters.yith-wcan-filters.yith-wcan-filters.yith-wcan-filters{
	border-radius: 4.6103px;
	background-color: $orange;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 22px 33px;
	margin-bottom: 30px;
	box-shadow: 0 11px 20px rgba(black, .3);

	@media (max-width: $b3){
		padding: 22px 15px;
	}

	h4,
	&:before{
		content: 'Filter by:';
		text-transform: uppercase;
		@include font-size(24);
		@include line-height(24,35.3722);
		font-weight: 600;
		font-family: $alt-font;
		color: $white;
		margin: 0;
		letter-spacing: .04em;
	}

	h4{
		text-transform: none;
		@include font-size(22);
		letter-spacing: 0;
		margin-right: 27px;

		@media (max-width: $b3){
			min-width: 85px;
		}
	}

	&:before{
		width: 134px;

		@media (max-width: $b2){
			width: 100%;
			margin-bottom: .5em;
		}
	}

	.filters-container{
		width: calc(100% - 134px);

		@media (max-width: $b2){
			width: 100%;
		}

		form{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-between;

			&:after{
				display: none;
			}

		}

		.filter-content{
			//min-width: 100px;
			flex-grow: 1;

			.yith-wcan-dropdown,
			select,
			input{
				background-color: rgba(white, .9);
			}
		}

		.yith-wcan-filter{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin: 0;
			width: 33.33%;
			width: calc(33.33% - 19px);

			@media (max-width: $b3){
				width: 100%;
				margin-bottom: 1em;
			}
		}
	}

	.yith-wcan-dropdown{
		background-color: white;
		border: 1px solid white;
		color: $grey-dark;
		border-radius: 3.329px;
		padding: 8px 17px;
		padding-right: 32px;
		@include font-size(19);
		background-image: url(../images/select-icon.svg);
		background-position: 100% 50%;
		background-repeat: no-repeat;

		&:after{
			display: none;
		}

		.dropdown-wrapper{
			padding: 8px 17px;
			border-radius: 3.329px;
			background-color: white;
			border: 1px solid white;
			box-shadow: 0 0 10px rgba($grey-darker, .3);

			a{
				display: block;
				color: $grey-dark;

				&:hover,
				&:focus{
					color: $orange;
				}
			}

			.active{

				a{
					color: $orange;
				}
			}
		}
	}
}



.p-item{
	display: flex;
	margin-bottom: 20px;

	.inner{
		background-color: white;
		border-radius: 4.6103px;
		width: 100%;
		position: relative;
	}

	.img-con{
		padding-top: 62%;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: 57% auto;
	}

	.info{
		padding: 8px 28px 73px;

		@media (max-width: 1250px){
			padding: 8px 15px 73px;
		}

		@media (max-width: 1194px){
			padding: 8px 15px 123px;
		}

		@media (max-width: $b3){
			padding: 8px 15px 73px;
		}

		h6{
			letter-spacing: .02em;
			margin-bottom: .78em;
		}
	}

	.btn-con{
		margin-right: -5.5px;
		margin-left: -5.5px;
		max-width: calc(100% + 5.5px + 11px);
		width: calc(100% + 5.5px + 11px);
		position: absolute;
		left: 36px;
		left: 26.5px;
		bottom: 24px;

		@media (max-width: 1250px){
			max-width: calc(100% - 15px - 10px);
			width: calc(100% - 15px - 10px);
			left: 15px;

		}

		.btn{
			margin-right: 5.5px;
			margin-left: 5.5px;
		}

		.small{
			padding-left: 22px;
			padding-right: 22px;
			min-width: 0;
		}
	}

	.price{
		margin-bottom: .2em;

		.reg,
		.sale{
			@include font-size(22);
			font-family: $alt-font;
			font-weight: 600;
			color: $orange;
			display: inline-block;
		}

		.reg.sale{
			color: lighten($grey-light, 20);
			@include font-size(16);
			text-decoration: line-through;
		}

		.amount-of{
			@include font-size(18);
			font-family: $main-font;
			font-weight: 400;
			color: $grey-dark;
			display: inline-block;
			margin-left: .1em;
		}
	}

	.min{
		@include font-size(15);
		font-style: italic;
		letter-spacing: .01em;
		margin-bottom: 1.4em;
		color: $grey-light;
	}
}

.exceptional-reviews-section{
	padding-top: 105px;
	padding-bottom: 167px;
	text-align: center;

	@media (max-width: $b3){
		padding-top: 46px;
		padding-bottom: 46px;
	}

	*{
		color: white;
	}

	h3{
		margin-bottom: .65em;
	}

	.review-slide{
		width: calc(100% - 240px);
		margin: 0 auto;

		@media (max-width: 1300px){
			width: calc(100% - 124px);
		}

		@media (max-width: $b3){
			width: calc(100% - 20px);	
		}

		.slick-track{
			display: flex;
			align-items: center;
		}

		.slick-slide{
			margin: 0 10px;
		}

		blockquote{
			margin-bottom: 0;
		}
	}
}

.interested-section{
	background-color: white;
	padding-top: 51px;
	padding-bottom: 21px;
	text-align: center;
	box-shadow: 0 -7px 7px rgba(black, .05);

	.col-12{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		h3{
			margin-bottom: 34px;
			letter-spacing: .0em;

			@media (max-width: $b3){
				width: 100%;
			}
		}

		.btn{
			margin: 0 0 34px 39px;
			box-shadow: 0 11px 22px rgba(black, .22);

			@media (max-width: $b3){
				margin: 0 auto;
			}
		}
	}
}

.simple-hero-section{
	background-image: url(../images/wave-back.svg);
	background-repeat: no-repeat;
	text-align: center;
	padding-top: 81px;
	padding-bottom: 66px;

	@media (max-width: $b3){
		padding-top: 66px;
		padding-bottom: 50px;
	}

	*{
		color: white;
	}
}

.dark-wrap-con{
	background-image: url(../images/wave-back.svg);
	background-repeat: no-repeat;
	
	.simple-hero-section,
	.comparison-section{
		background-image: none;
	}
}

.text-with-image-section + .text-with-image-section{

	&:last-of-type{
		padding-bottom: 65px;

		@media (max-width: $b3){
			padding-bottom: 46px;
		}
	}
}

.intro-and-ranges-section + .text-with-image-section{
	padding-top: 10px;

	@media (max-width: $b3){
		padding-top: 0px;
	}
}

.text-with-image-section{
	padding-top: 91px;

	@media (max-width: $b3){
		padding-top: 46px;
	}

	&.right{
		
		.text{
			order: 2;

			@media (min-width: $b3 + 1){
				padding-left: 62px;
			}
		}
	}

	&.left{
		
		.text{

			@media (max-width: $b3){
				order: 2;
			}

			@media (min-width: $b3 + 1){
				padding-right: 62px;
			}
		}

		.image{

		}
	}

	.text{
		padding-top: 8px;
		
		@media (min-width: $b3 + 1){
			flex: 0 0 65.16667%;
			max-width: 65.16667%;
		}
	}

	.image{

		@media (min-width: $b3 + 1){
			flex: 0 0 34.83333%;
			max-width: 34.83333%;
		}

		@media (max-width: $b3){
			margin-bottom: 1em;
		}
	}

	h2{
		letter-spacing: -.005em;

	}

	p{
		margin-bottom: 1.42em;
	}

	.btn-con{
		padding-top: 9px;
	}
}

.faq-section{
	padding-top: 82px;
	padding-bottom: 56px;

	@media (max-width: $b3){
		padding-top: 46px;
		padding-bottom: 46px;
	}

	.title-con{
		padding-bottom: 29px;
	}
}

dl.accordion{

	dt{
		padding: 31px 0;
		padding-left: 70px;
		padding-right: 70px;
		@include font-size(24);
		@include line-height(24,28.8);
		font-family: $alt-font;
		font-weight: 600;
		color: $grey-darker;
		cursor: pointer;
		position: relative;
		transition: $time;
		margin-bottom: 0;

		@media (max-width: $b3){
			padding: 10px 0;
			padding-left: 50px;
		}

		&:hover{
			color: $orange;
		}

		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 52%;
			left: 6px;
			width: 24px;
			height: 3px;
			background-color: $orange;
			transition: $time;
		}

		&:before{
			transform: translateY(-50%) rotate(-90deg);
		}

		&:after{
			transform: translateY(-50%) rotate(0deg);
		}

		&.open{

			&:before{
				transform: translateY(-50%) rotate(0deg);
			}
			
			+ dd{
				max-height: 2000px;
				transition: $time*2;
			}
		}
	}

	dd{
		margin: 0 auto;
		border-bottom: 1px solid #CBCCCF;
		max-height: 0;
		overflow: hidden;
		transition: $time;

		.inner{
			padding-left: 70px;
			padding-right: 70px;
			padding-bottom: 15px;
		}
	}

	p{
		margin-bottom: 1.5em;
	}

}

.ranges-simple-section{
	background-color: $grey-lighter;
	padding-top: 63px;
	padding-bottom: 63px;

	@media (max-width: $b3){
		padding-top: 46px;
		padding-bottom: 63px;
	}

	.row{
		
		&.bottom{
			padding-top: 19px;
			background-color: white;
			padding-left: 9.5px;
			padding-right: 9.5px;
		}
	}

	.title{
		padding-bottom: 14px;
		
		h2{
			padding-left: 6px;
		}
	}

	.col-lg-3{
		text-align: center;
		margin-bottom: 9.5px + 9.5px;

		.inner{
			position: relative;

			a{
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}

		h4{
			margin: 0;
			color: white;
		}

		img{
			width: 100%;
		}

		.title{
			padding: 10px 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 4.6em;
		}

		&.orange{
			
			.title{
				background-color: $orange;
			}
		}

		&.red{
			
			.title{
				background-color: $red;
			}
		}

		&.brown{
			
			.title{
				background-color: $brown;
			}
		}
	}
}

.blog-section{
	padding-top: 86px;
	padding-bottom: 47px;
	background-color: $grey-lighter;

	@media (max-width: $b3){
		padding-top: 46px;
		padding-bottom: 46px;
	}

	.title-con{
		text-align: center;
		padding-bottom: 56px;
	}


	@media (min-width: $b3 + 1){

		.container{
			max-width: 1257px;
		}
		
		.col-12,
		.container{
			padding-right: 16px;
			padding-left: 16px;
		}

		.row{
			margin-right: -16px;
			margin-left: -16px;
		}
	}

	#blog{

		&.loading{
			pointer-events: none;
			opacity: .5;
		}
	}

	.ajax-btn-con{
		padding-top: 23px;

		&.loading{
			pointer-events: none;
			opacity: .5;
		}

		&.no-more{
			display: none;
		}

		.btn{
			padding-left: 20px;
			padding-right: 20px;
			margin-bottom: 0;
		}

	}
}

.b-item{
	margin-bottom: 37px;
	display: flex;

	.inner{
		width: 100%;
		display: flex;
		flex-direction: column;
		position: relative;

		a{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;

			&:hover,
			&:focus{
				
				~ .text-con{
					
					h4{
						color: $orange;
					}
				}
			}
		}

		.img-con{
			padding-top: 59%;
			display: block;
		}

		.text-con{
			flex: 1;
			background-color: white;
			text-align: center;
			padding: 29px 10px 25px;
			border-bottom: 3px solid $orange;

			.date{
				margin-bottom: 1em;
				width: 100%;
			}

			h4{
				transition: $time;
			}
		}
	}
}

.blog-hero-section{
	height: 482px;

	@media (max-width: $b2){
		height: 400px;
	}

	@media (max-width: $b3){
		height: 300px;
	}
}

.blog-single-section{
	padding-top: 63px;
	padding-bottom: 55px;
	background-color: $grey-lighter;

	@media (max-width: $b3){
		padding-top: 46px;
		padding-bottom: 46px;
	}

	.container{
		max-width: 1240px;
	}

	h2{
		margin-bottom: 1.06em;
	}

	.col-lg-10{

		@media (min-width: $b3 + 1){
			flex: 0 0 calc(100% - 290px);
			max-width: calc(100% - 290px);
			padding-right: 68px;
			border-right: 1px solid #C8C8C8;
		}

		p{
			margin-bottom: 1.45em;
		}

		.share{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding-top: 16px;

			@media (max-width: $b3){
				justify-content: center;

				.btn-con{
					justify-content: center;
				}
			}

			h4{
				margin-bottom: 5px;
				margin-right: 27px;
			}

			.btn{
				padding-left: 20px;
				padding-right: 20px;

				i{
					margin-right: 17.5px;
					@include font-size(18);
				}
			}
		}
	}

	.col-lg-2{

		@media (min-width: $b1 + 1){
			padding-right: 15px;
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 290px;
			max-width: 290px;
			padding-left: 47px;
		}

		.posted-on{
			background-image: url(../images/cal.svg);
			background-repeat: no-repeat;
			background-position: 0 50%;
			@include font-size(24);
			text-transform: uppercase;
			font-family: $alt-font;
			padding: 18px 0 18px 70px;
			color: $grey-darker;
			margin-bottom: 1em;

			@media (max-width: $b3){
				width: 180px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 1em;
			}

			.date{
				color: $orange;
			}
		}

		.b-item{

			.text-con{
				padding-top: 15px;
				padding-bottom: 8px;
			}
		}
	}
}

.simple-content-section + .form-section{
	padding-top: 0px;
}

.form-section{
	padding-top: 100px;
	padding-bottom: 100px;
	background-color: #F6F6F6;

	@media (max-width: $b3){
		padding-top: 46px;
		padding-bottom: 46px;
	}

	.container{
		max-width: 1240px;
	}
}

.simple-content-section{
	padding-top: 100px;
	padding-bottom: 100px;
	background-color: #F6F6F6;

	@media (max-width: $b3){
		padding-top: 46px;
		padding-bottom: 46px;
	}

	.container{
		max-width: 1240px;
	}
}

.latest-blog-posts-section,
.related-products-section{
	background-color: $grey-lighter;
	padding-top: 79px;
	padding-bottom: 154px;

	@media (max-width: $b3){
		padding-top: 46px;
		padding-bottom: 112px;
	}

	.title-con{
		text-align: center;

		h3{
			letter-spacing: 0;
			padding: 0 60px;
			margin-bottom: .86em;

			@media (max-width: $b3){
				padding: 0 52px;
				@include font-size(27);
			}
		}
	}

	.latest-slide,
	.related-products-slide{
		margin: 0 -9.5px;
		width: calc(100% + 9.5px + 9.5px);
		max-width: calc(100% + 9px + 9px);

		.slick-arrow{
			background-color: $orange;
			transform: none;
			top: -94px;
			
			@media (max-width: $b1){
				top: -85px;
			}

			@media (max-width: $b2){
				top: -77px;
			}

			@media (max-width: $b3){
				top: -66px;
			}

			&:hover{
				background-color: $grey-darker;
			}

			&.slick-next{
				background-image: url(../images/slide-next-white.svg);
				right: 9px;
			}

			&.slick-prev{
				background-image: url(../images/slide-prev-white.svg);
				left: 9px;
			}
		}

		.amount-of.amount-of.amount-of{
			margin-left: 0.3em;
		}

		.slick-dots{
			bottom: -59px;

			li{

				button{
					background-color: rgba($grey, .4);
					border: none;
				}

				&.slick-active{

					button{
						background-color: rgba($grey, 1);
					}
				}
			}
		}

		.slick-track{
			display: flex;
		}

		.slick-slide{
			margin: 0 9.5px;
			height: auto;
			display: flex;
		}
	}
}

.latest-blog-posts-section{
	padding-bottom: 79px;

	@media (max-width: $b3){
		padding-bottom: 46px;
	}
}

.woocommerce-notices-wrapper{
	width: 100%;
}

.woocommerce.woocommerce{

	.woocommerce-breadcrumb{
		max-width: 1261px;
		margin: 0 auto;
		padding: 42px 9.5px 39px;
		@include font-size(18);

		@media (max-width: $b3){
			@include font-size(12);
		}

		a{
			color: $orange;
		}

		+ .woocommerce-notices-wrapper{
			max-width: 1261px;
			margin: 0 auto;
		}
	}
}

.single-product{
	background-color: #F6F6F6;
}

.product-single-section{
	background-color: #F6F6F6;
	padding-bottom: 76px;

	@media (max-width: $b3){
		padding-bottom: 5px;
	}

	.image-side{
		
		@media (max-width: $b3){
			margin-bottom: 1em;
		}

		@media (min-width: $b3 + 1){
			padding-right: 20px;
		}
	}

	.info-side{

		@media (min-width: $b3 + 1){
			padding-left: 43px;
			margin-top: -.4em;
		}

		.product_meta{
			display: none;
		}

		.product_title{
			margin-bottom: 0;
		}

		.price{
			margin-bottom: .2em;
	
			.reg,
			.sale{
				@include font-size(46);
				@include line-height(46,58);
				font-family: $alt-font;
				font-weight: 600;
				color: $orange;
				display: inline-block;

				@media (max-width: $b1){
					@include font-size(40);
				}
			
				@media (max-width: $b2){
					@include font-size(35);
				}
			
				@media (max-width: $b3){
					@include font-size(32);
				}
			}
	
			.reg.sale{
				color: lighten($grey-light, 20);
				@include font-size(16);
				text-decoration: line-through;
			}
	
			.amount-of{
				@include font-size(18);
				letter-spacing: .01em;
				font-family: $main-font;
				font-weight: 600;
				color: $grey-dark;
				display: inline-block;
				margin-left: .2em;

				@media (max-width: $b2){
					@include font-size(17);
				}
			
				@media (max-width: $b3){
					@include font-size(16);
				}
			}
		}

		.tabs{
			padding-top: 19px;
		}
	}

	+ .woocommerce-tabs{
		display: none;
	}

	.product-slide{
		border-radius: 4.6103px;
		background-color: white;

		.slick-dots{
			bottom: 49px;

			li{

				button{
					background-color: rgba(#C0C0BF, 1);
					border: none;
				}

				&.slick-active{

					button{
						background-color: rgba(#1D1D1B, 1);
					}
				}
			}
		}


		// .slick-track{
		// 	display: flex;
		// }

		.slider_item{
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			//height: auto;
			border-radius: 4.6103px;

			img{
				border-radius: 4.6103px;
			}
		}
	}

	.cart.cart.cart.cart{

		.quantity{

			select,
			input{
				margin-right: .92em;
				margin-bottom: 19px;
			}
		}

		.button{
			padding-top: 11.75px;
			padding-bottom: 11.75px;
			font-size: 20.2616px !important;
			font-size: 2.02616rem !important;
			letter-spacing: 0.035em;
		}
	}
}

.quantity{
	
	input{
		margin-bottom: 19px;
		@include font-size(20.2616);
		letter-spacing: .55em;
		font-weight: 600;
		font-family: $alt-font;
		padding: 12px 17px;
	}

	select{
		@include font-size(20.2616);
		line-height: 51px;
		height: 53px;
		font-weight: 600;
		font-family: $alt-font;
		margin-bottom: 0;
	}
}

[colspan="6"][colspan="6"][colspan="6"][colspan="6"][colspan="6"]{

	.input-text{
		@include font-size(16);
		padding-top: 8.75px;
		padding-bottom: 8.75px;

		&::-webkit-input-placeholder {
			@include font-size(16);
		}
		 
		&:-moz-placeholder { /* Firefox 18- */
			@include font-size(16);
		}
		
		&::-moz-placeholder {  /* Firefox 19+ */
			@include font-size(16);
		}
		
		&:-ms-input-placeholder {
			@include font-size(16);
		}
	}
}


.woocommerce.woocommerce.woocommerce,
.woocommerce-page.woocommerce-page.woocommerce-page{

	div.woocommerce{
		max-width: $base-width + $grid-gutter-width;
		padding-right: $grid-gutter-width/2;
		padding-left: $grid-gutter-width/2;
		margin: 0 auto;
		padding-top: 100px;
		padding-bottom: 100px;

		@media (max-width: $b3){
			padding-top: 46px;
			padding-bottom: 46px;
		}
	}

	.form-row{
		margin-bottom: 0;
		padding: 0;

		input.input-text,
		textarea{
			margin-bottom: $input-bottom-margin;
		}

		label{
			line-height: 1.687;
		}
	}

	form .form-row-first,
	form .form-row-last,
	form .form-row-first,
	form .form-row-last{

		@media (max-width: $b4){
			width: 100%;
		}
	}

	.woocommerce-form-login__rememberme{
		margin-bottom: $input-bottom-margin;
	}

	[type="submit"]{
		margin-bottom: $input-bottom-margin;
	}

	form .form-row .required{
		color: $red;
	}

	form .show-password-input.display-password::after,
	form .show-password-input.display-password::after{
		color: $orange;
	}

	form.checkout_coupon,
	form.login,
	form.register{
		border: none;
		padding: 0;
		margin-top: 0;
	}

	form .form-row input.input-text,
	form .form-row textarea{
		line-height: 1.316em;
	}

	.select2-dropdown{
		max-width: none;
		border: 1px solid #ddd;
		border: 1px solid white;
		border-top: none;
		border-radius: 3.329px;
		top: calc(100% + 10px);
		box-shadow: 0 0 10px rgba($grey-darker, .3);

		.select2-results{
			padding: 0 17px 8px;
		}

		ul{

			li{
				margin-bottom: 5px;
				padding: 0;
				line-height: 2.056;
			}
		}

		.select2-search__field{
			
		}

		.select2-search{
			padding: 16px 17px 0;
		}


		.select2-results__option[data-selected=true]{
			background-color: white;
		}

		.select2-results__option--highlighted[data-selected]{
			color: $orange;
			background-color: $white;
			transition: $time;
		}

		.select2-search__field{
			margin-bottom: 0;
			border: 1px solid $grey-lighter;
			padding: 10px 17px;
		}
	}

	form .select2-container{
		margin-bottom: $input-bottom-margin;
		height: 47px;
		border: 1px solid white;
		background-color: white;
		border-radius: 3.329px;

		&--open{
			border: 1px solid $orange;

			.select2-selection__arrow.select2-selection__arrow{
				background-image: url(../images/select-icon.svg);
			}
		}

		&--focus{
			border: 1px solid $orange;
		}

		.select2-selection--single.select2-selection--single{
			border: none;
			background-color: transparent;
		}

		.select2-selection--single{
			height: 47px;
			border: 1px solid #ddd;
			background-color: white;
			border-radius: 0;
			line-height: 47px;
		}

		.select2-selection__rendered{
			height: 46px;
			line-height: 46px;
			padding-right: 32px;
			padding-left: 17px;

		}

		.select2-selection__arrow{
			height: 45px;
			background-image: url(../images/select-icon.svg);
			background-repeat: no-repeat;
			background-position: 100% 50%;
			width: 28px;

			b{
				display: none;
			}
		}
	}

	.woocommerce-terms-and-conditions-checkbox-text.woocommerce-terms-and-conditions-checkbox-text.woocommerce-terms-and-conditions-checkbox-text{
		
		&:after{
			content: ''!important;
			margin: 0!important;
		}
	}



















	#respond input#submit,
	a.button,
	button.button,
	input.button{
		@extend .btn;
		min-width: 103px;
		padding: 9.5px 27px;

		&:disabled{
			padding: 9.5px 27px;
		}
	}

	[name="add-to-cart"][name="add-to-cart"]{
		background-image: url(../images/add.svg);
		background-repeat: no-repeat;
		padding-left: 98px;
		padding-right: 73px;
		background-position: 59px 50%;
	}

	legend{
		padding-left: 10px;
		padding-right: 10px;
		margin-left: -10px;
	}

	fieldset{
		margin: 0 0 20px;
		padding: 18px 18px;
		border-color: $orange;
		border-style: solid;
		border-width: 1px;
	}

	.woocommerce-Address-title{
		display: flex;
		position: relative;
		margin-bottom: 12px;

		h3{
			width: 100%;
			padding-right: 50px;
			margin-bottom: 0;
		}

		.edit{
			position: absolute;
			bottom: .25em;
			right: 0;

			&:hover,
			&:focus{
				color: $orange;
			}
		}
	}

	a.remove{
		color: $red!important;
		font-weight: 400;
		line-height: 23px;

		&:hover,
		&:focus{
			background-color: $red;
			color: $white!important;
		}
	}

	.coupon.coupon.coupon{
		display: flex;
		align-items: center;

		input,
		.button{
			margin-bottom: 10px;
		}

		input{
			min-width: 150px;

			@media (max-width: $b3){
				min-width: 0;
			}
		}

		.button{
			padding: 6.5px 20px;
			width: auto;

			@media (max-width: $b3){
				padding: 6.5px 15px;
			}
		}
	}

	table.shop_table th{

		@media (max-width: $b3){
			padding: 6px 8px;
		}
	}

	table.shop_table td{

		@media (max-width: $b3){
			padding: 6px 8px;
		}
	}

	.cart_totals{

		table{

			th{
				border-top: 1px solid #ddd;
			}

			td{
				width: 95px!important;

				@media (max-width: $b3 + 1){
					width: 100%!important;
				}
			}

			.order-total .woocommerce-Price-amount{
				font-weight: 700;
			}
		}
	}

	table{

		.product-subtotal{
			font-weight: 700;
		}

		&.woocommerce-cart-form__contents{


			@media (max-width: $b3){

				tbody{

					tr:first-of-type{

						td:first-of-type{
							border-top: none!important;
						}
					}
				}
			}
		}

		.actions{

			@media (max-width: $b3){
				padding-left: 0;
				padding-right: 0;
			}

			.button{
				width: auto;
				padding: 6.5px 20px;

				&:disabled{

					&:hover,
					&:focus{
						background-color: $orange;
						color: $orange!important;
					}
				}
			}
		}

		.cart_item{

			@media (max-width: $b3){
				padding: 0;
			}
		}

		.product-remove{

			@media (max-width: $b3){
				padding-bottom: 0;
			}
		}

		.product-thumbnail + .product-name{

			@media (max-width: $b3){
				border-top: none!important;
			}
		}

		img{
			width: 75px;
		}

		.product-remove{
			width: 45px !important;

			@media (max-width: $b3 + 1){
				width: 100%!important;
			}
		}

		.product-thumbnail,
		.product-price,
		.product-quantity,
		.product-subtotal{
			width: 95px !important;

			@media (max-width: $b3 + 1){
				width: 100%!important;
			}
		}

		.product-quantity{

			@media (max-width: $b3 + 1){
				display: flex;
				align-items: center;
				justify-content: space-between;

			}
		}

		// input{
		// 	border-bottom: 1px solid #ddd!important;

		// 	&:focus{
		// 		border-bottom: 1px solid $orange!important;
		// 	}
		// }

		.screen-reader-text{
			height: 0;
			width: 0;
		}

		&.shop_table{

			td{
				border-top: 1px solid #ddd;
			}
		}

		.product-name{

			a{
				color: $colour;
				text-decoration: none;

				&:hover,
				&:focus{
					color: $orange;
				}
			}
		}
	}

	.cart-collaterals{

		h2{
			font-weight: 200;
			color: $orange;
		}
	}

	.shop_table{

		// td:last-of-type{
		// 	width: 95px!important;
		// 	width: 210px!important;

		// 	label:before{
		// 		margin-top: 2px;
		// 	}

		// 	@media (max-width: $b3 + 1){
		// 		width: 100%!important;

		// 		&.product-total{
		// 			width: 95px!important;
		// 			width: 210px!important;

		// 			label:before{
		// 				margin-top: 2px;
		// 			}
		// 		}
		// 	}
		// }
	}

	.woocommerce ul#shipping_method li{
		padding-left: 0;
	}

	.woocommerce-checkout-review-order-table{

		.cart-subtotal,
		.order-total{

			td:last-of-type{

				@media (max-width: $b3 + 1){
					width: 95px!important;

				}
			}
		}
	}

	.woocommerce-checkout{

		#createaccount{

			+ span:last-of-type{
				margin-bottom: 18px;
			}
		}

		#terms{

			+ span{

				&:after{
					content: '*';
					color: $red;
					font-weight: 700;
					margin-left: .25em;
				}
			}

			~ span.required{
				display: none;
			}
		}

		h3{
			color: $orange;
			border-bottom: 1px solid #ddd;
			padding-bottom: 10px;
		}

		.input-checkbox{
			display: none;
		}

		#order_review_heading{
			padding-top: 20px;
		}
	}

	form .form-row.woocommerce-validated .select2-container,
	form .form-row.woocommerce-validated input.input-text,
	form .form-row.woocommerce-validated select{
		border-color: $green;
	}

	form .form-row.woocommerce-invalid .select2-container,
	form .form-row.woocommerce-invalid input.input-text,
	form .form-row.woocommerce-invalid select{
		border-color: $red;
	}

	form .form-row.woocommerce-invalid label{
		color: $red;
	}

	.woocommerce-checkout-payment{
		background-color: transparent!important;

		.input-checkbox{
			display: none;
		}

		.place-order{
			padding: 20px 0 0!important;
		}

		.woocommerce-terms-and-conditions-wrapper{
			margin-bottom: 20px;
		}
	}

	tabel.order_details tbody{

		@media (max-width: $b3){

			.product-name{
				width: 100%!important;
			}
		}
	}

	.woocommerce-order{

		h2{
			font-weight: 200;
			color: $orange;
		}
	}

	.woocommerce-customer-details address{
		border: 1px solid $orange;
		border-radius: 0;
		line-height: 1.4em;

		p{
			line-height: 1.5em;
		}
	}

	form .form-row-first, 
	form .form-row-last, 
	form .form-row-first, 
	form .form-row-last{
		width: calc(50% - (#{$grid-gutter-width}/2));

		@media (max-width: 575px){
			width: 100%;
		}
	}

	.col2-set{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.col-1,
		.col-2{
			padding-left: 0;
			padding-right: 0;
			width: 50%;
			flex: 0 0 50%;
			max-width: 50%;

			@media (max-width: $b3){
				width: 100%;
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		@media (min-width: $b3 + 1){
			.col-1{
				padding-right: 9.5px;
			}

			.col-2{
				padding-left: 9.5px;
			}
		}
	}
}

.woocommerce-page{

	.main-wrapper{
		background-color: #F6F6F6;
	}
}

.woocommerce-checkout{

	.woocommerce &{
		padding-bottom: 30px;
	}
}

.woocommerce-error.woocommerce-error,
.woocommerce-info.woocommerce-info,
.woocommerce-message.woocommerce-message{
	padding: 10px 10px 10px 3.5em;
	border-top-width: 1px;
	margin-top: 4px;
	margin-bottom: 20px;
	line-height: 35px;
	background-color: white;
	@include font-size(18);
	line-height: 1.3;

	@media (max-width: $b2){
		@include font-size(17);
	}

	@media (max-width: $b3){
		@include font-size(16);
	}

	&:before{
		top: .6em;
	}

	@media (max-width: $b3){

		.button{
			width: 100%;
		}
	}
}

.woocommerce-message.woocommerce-message{
	border-top-color: $green;

	&:before{
		color: $green;
	}
}

.woocommerce-error.woocommerce-error{
	border-top-color: $red;

	&:before{
		color: $red;
	}
}

.woocommerce-info.woocommerce-info{
	border-top-color: $orange;

	&:before{
		color: $orange;
	}
}

.woocommerce-view-order{

	@media (max-width: $b3){

		.product-name.product-name.product-name.product-name.product-name{
			width: 100%!important;
		}
	}
}

.page-id-3220{

	.main-wrapper{
		background-color: #F6F6F6;
	}
}

.page-id-3220{

	&.woocommerce-edit-address{

		.woocommerce-MyAccount-content.woocommerce-MyAccount-content.woocommerce-MyAccount-content{
			padding-top: 2px;
		}
	}


	&.woocommerce-edit-account{

		.woocommerce-MyAccount-content.woocommerce-MyAccount-content.woocommerce-MyAccount-content{
			padding-top: 0;
		}
	}

	.woocommerce{
		display: flex;
		flex-wrap: wrap;

		.woocommerce-MyAccount-navigation{
			width: 200px;

			@media (max-width: $b3){
				width: 100%;
			}

			ul{

				@media (max-width: $b3){
					padding-bottom: 10px;
					border-bottom: 1px solid #ddd;
					display: flex;
					flex-direction: column;
				}

				&.open{

					li.is-active{
						background-image: url(../images/arrow-up-orange.svg);
					}

					li:not(.is-active){
						display: block;
					}
				}

			}

			li{
				padding-left: 0;
				line-height: 0;

				@media (max-width: $b3){

					&:nth-of-type(1){
						order: 2;
					}

					&:nth-of-type(2){
						order: 3;
					}

					&:nth-of-type(3){
						order: 4;
					}

					&:nth-of-type(4){
						order: 5;
					}

					&:nth-of-type(5){
						order: 6;
					}

					&:nth-of-type(6){
						order: 7;
					}

					&:nth-of-type(7){
						order: 8;
					}

					&.is-active{
						padding-right: 30px;
						background-image: url(../images/arrow-down-orange.svg);
						background-repeat: no-repeat;
						background-position: 100% 11px;
						cursor: pointer;

						a{
							pointer-events: none;
						}
					}

					&:not(.is-active){
						display: none;
					}

				}

				&.is-active{

					@media (max-width: $b3){
						order: 1;
					}

					a{
						padding-left: 9px;
						background-position: 0 .8em;
						background-position: 0 55%;
						color: $orange;
					}
				}

				a{
					text-decoration: none;
					line-height: 2em;
					display: inline-block;
					background-image: url(../images/arrow-right-orange.svg);
					background-repeat: no-repeat;
					overflow: hidden;
					background-position: -10px .8em;
					background-position: -10px 55%;


					&:hover,
					&:focus{
						padding-left: 9px;
						background-position: 0 .8em;
						background-position: 0 55%;
					}
				}
			}

			+ .woocommerce-MyAccount-content{
				width: calc(100% - 200px);
				padding-top: 5px;

				@media (max-width: $b3){
					width: 100%;
				}
			}
		}
	}
}

.four-zero-four-section{
	padding-top: 100px;
	padding-bottom: 100px;
	text-align: center;
	min-height: calc(100vh - 164px - 369.5px - 217.48px);

	@media (max-width: $b3){
		padding-top: 42px;
		padding-bottom: 42px;
	}
}

.how-it-works-section{
	padding-top: 52px;
	padding-bottom: 23px;

	@media (max-width: $b3){
		padding-top: 42px;
		padding-bottom: 22px;
	}

	.title-con{
		margin-bottom: 0.25em;
	}

	img{
		width: 100%;
		margin-bottom: 1.1em;
	}

	h4{
		margin-bottom: .3em;
	}

	.col-6{
		margin-bottom: 20px;
	}

	@media (min-width: $b3 + 1){

		.container{
			max-width: 1281px;
		}
		
		.container,
		.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
			padding-right: 19px;
			padding-left: 19px;
		}

		.row{
			margin-right: -19px;
			margin-left: -19px;
		}
	}
}

.video-section{
	padding-top: 42px;
	padding-bottom: 42px;
}

html.pop-open{
	overflow: hidden;
}

.val-popup{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($grey-dark, .75);
	z-index: 999;
	opacity: 0;
	visibility: hidden;
	transition: $time;

	&.open{
		opacity: 1;
		visibility: visible;
	}

	.pop{
		border-radius: 4.6103px;
		background-color: white;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 550px;
		max-width: calc(100% - 19px);
		min-height: 100px;
		padding: 40px 15px;
		text-align: center;
		box-shadow: 0 11px 20px rgba(black, .3);
	}

	.mobile{
		background-image: url(../images/mobile-grey.svg);
		background-repeat: no-repeat;
		padding-left: 19px;
		background-position: 0 65%;
	}

	.email{
		background-image: url(../images/email-grey.svg);
		background-repeat: no-repeat;
		padding-left: 24px;
		background-position: 0 65%;
	}

	.close{
		position: absolute;
		top: 10px;
		right: 10px;
		width: 32px;
		height: 32px;
		cursor: pointer;

		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 32px;
			height: 2px;
			background-color: $grey-darker;
			transition: $time;
		}

		&:before{
			transform: translate(-50%,-50%) rotate(45deg);
		}

		&:after{
			transform: translate(-50%,-50%) rotate(-45deg);
		}

		&:hover,
		&:focus{
			
			&:before,
			&:after{
				background-color: $orange;
			}
		}
	}
}

.woocommerce-cart{

	.woocommerce-shipping-totals.shipping{
		display: none;
	}
}

.woocommerce-checkout{

	#ship-to-different-address{
		float: none!important;

		.woocommerce-form__label.woocommerce-form__label-for-checkbox.checkbox{
			margin-bottom: 0;
		}
	}

	.woocommerce-shipping-totals.shipping{

		@media (max-width: $b3){
			
			[data-title=Shipping]{
				width: 40%!important;
			}
		}
	}

	table, 
	table tr, 
	table th, 
	table td{

		@media (max-width: $b3){
			@include font-size(14);
		}
	}
}
	