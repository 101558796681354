//TABS
// <div class="tab-container">
// 	<div class='tabs'>
// 		<div class="tab active" rel="tab1">Tab1</div>
// 		<div class="tab" rel="tab2">Tab2</div>
// 	</div>

// 	<h3 class="tab-accordion-heading active" rel="tab1">Tab1</div>
// 	<div id="tab1" class="tab-content active">
// 		<div class="inner">
// 			content here
// 		</div>
// 	</div>

// 	<div class="tab-accordion-heading" rel="tab2">Tab1</div>
// 	<div id="tab2" class="tab-content">
// 		<div class="inner">
// 			content here
// 		</div>
// 	</div>
// </div>

//$('.tabs .tab, .tab-accordion-heading').click(function() {
//	$('.tab-content, .tabs .tab, .tab-accordion-heading').removeClass('active');
//
//	var activeTab = $(this).attr("rel");
//	$("#"+activeTab).addClass('active');
//	$(this).addClass("active");
//	$(".tab-accordion-heading[rel^='"+activeTab+"'], .tabs .tab[rel^='"+activeTab+"']").addClass("active");
//});

////hash tabs
//
////add hash to url
//$('ul.tabs li').click(function(event) {
//	var activeTab = $(this).attr("rel");
//	window.location.hash = 'tab-' + activeTab;
//});
//
//checks for new hash
//$(window).on('hashchange',function(){
//	var newHash = location.hash.slice(5);
//	$('.tab-container .tabs li').removeClass('active')
//	$('.tab-container .tab-content').removeClass('active')
//	$("#"+newHash).addClass('active');
//	$("ul.tabs li[rel='"+newHash+"']").addClass("active");
//});
//
////on load check for a hash
//var hashLoad = window.location.hash.slice(5);
//if(hashLoad){
//	$('.tab-container .tabs li').removeClass('active')
//	$('.tab-container .tab-content').removeClass('active')
//	$("#"+hashLoad).addClass('active');
//	$("ul.tabs li[rel='"+hashLoad+"']").addClass("active");
//}

.tab-container.tab-container{
	position: relative;

	@media (max-width: $b2){
		padding-bottom: 0;
	}

	.tabs{
		padding: 0;
		text-align: center;
		margin: 0 -11px;
		width: calc(100% + 11px + 11px);
		max-width: calc(100% + 11px + 11px);
		display: flex;
		justify-content: flex-start;
		flex-wrap: nowrap;
		position: relative;

		@media (max-width: $b2){
			flex-direction: column;
		}

		.tab{
			@include font-size(22);
			letter-spacing: .055em;
			font-family: $alt-font;
			text-align: center;
			text-decoration: none;
			cursor: pointer;
			transition: $time;
			font-weight: 600;
			margin: 0;
			color: $grey;
			padding: 12px 0;
			position: relative;
			transition: $time;
			margin: 0 11px;
			text-transform: uppercase;

			&:after{
				content: '';
				position: absolute;
				left: 2px;
				right: 2px;
				bottom: 0;
				height: 2px;
				background-color: transparent;
			}

			@media (max-width: $b1){
				@include font-size(20);
			}

			@media (max-width: $b2){
				@include font-size(16);
				padding: 10px 2px;
				text-align: center;
				width: 100%!important;
				margin: 0 0 5px!important;
			}

			&:hover{
				opacity: 1;
			}

			&.active,
			&:hover{
				color: $grey-darker;

				&:after{
					background-color: $orange;
				}
			}

			&.active{
				cursor: default;
			}
		}
	}

	.tab-accordion-heading{
		display: none;
		border-bottom: 1px solid $orange;
		color: $orange;
		text-align: center;
		@include font-size(22);
		padding: 13px 20px 13px;
		background-image: url(../images/acc-down.svg);
		background-repeat: no-repeat;
		background-position: 100% 50%;

		&.active{
			background-image: url(../images/acc-up.svg);
		}


		@media (min-width: $b3 + 1){
			display: none;
		}
	}

	.tab-content{
		max-height: 0;
		overflow: hidden;

		@media (max-width: $b3){
			float: none;
			//width: 100%;
		}

		&.active,
		&.slick-acive{
			max-height: 99999px;
		}

		.inner{
			padding: 37px 0 7px;

			@media (max-width: $b2){
				padding: 14px 8px;
			}

			p{
				margin-bottom: 1.45em;
			}
		}
	}
}
