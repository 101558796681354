@font-face {
    font-family: 'Barlow';
    src: url('../fonts/Barlow-Regular.woff2') format('woff2'),
        url('../fonts/Barlow-Regular.woff') format('woff'),
        url('../fonts/Barlow-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('../fonts/Barlow-Italic.woff2') format('woff2'),
        url('../fonts/Barlow-Italic.woff') format('woff'),
        url('../fonts/Barlow-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url('../fonts/Barlow-Medium.woff2') format('woff2'),
        url('../fonts/Barlow-Medium.woff') format('woff'),
        url('../fonts/Barlow-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('../fonts/Barlow-SemiBold.woff2') format('woff2'),
        url('../fonts/Barlow-SemiBold.woff') format('woff'),
        url('../fonts/Barlow-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('../fonts/Barlow-Bold.woff2') format('woff2'),
        url('../fonts/Barlow-Bold.woff') format('woff'),
        url('../fonts/Barlow-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow Condensed';
    src: url('../fonts/BarlowCondensed-SemiBold.woff2') format('woff2'),
        url('../fonts/BarlowCondensed-SemiBold.woff') format('woff'),
        url('../fonts/BarlowCondensed-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Caveat';
    src: url('../fonts/Caveat-Regular.woff2') format('woff2'),
        url('../fonts/Caveat-Regular.woff') format('woff'),
        url('../fonts/Caveat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}



//BREAK POINTS
$b1:				1242px - 1px; //$base-width + $grid-gutter-width - 1px
$b2:				992px - 1px; //992px - 1px
$b3:				769px - 1px; //769px - 1px
$b4:				576px - 1px; //576px - 1px
$b5:				480px - 1px; //450px

//xs: 0,
//sm: 576px,
//md: 768px,
//lg: 992px,
//xl: $base-width

//!!COLOURS

$red:				#E5231C;

$green:				#00a63a;

$brown:				#A2673F;

$orange:			#FF9E18;

$grey-lighter:		#EBEBEB;
$grey-light:		#8D8D8D;
$grey:				#707372;
$grey-dark:			#595959;
$grey-darker:		#222222;

$white:				#ffffff;
$black:				#000000;

$colour: 			$grey;
$main-bg-colour:	$white;

$font-bar:			'Barlow', sans-serif;
//regular - 400
//medium - 500
//semi-bold - 600
//bold - 700

$font-barc:			'Barlow Condensed', serif;
//light - 400

$font-cav:			'Caveat', serif;
//light - 400

$main-font:			$font-bar;
$alt-font:			$font-barc;
$alt-font2:			$font-cav;

//!!GRID SETTINGS
$grid-columns:		12 !default;
$grid-gutter-width:	19px !default;

//!!PAGE WIDTH
$base-width:		1242px;

//!!SITE TRANSITIONS
$time:				.25s;

//!!FONT SIZE DIVIDER
$divider:			1.25;
$divider-big:		1.4;

