//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: relative;
	top: 0;
	left: 0;
	transition: $time;
	background-color: transparent;

	// .home &{
	// 	background-color: transparent;
	// }

	@media (max-width: $b2){
		z-index: 111;
	}

	// .scroll-down &,
	// .scroll-up &{

	// 	ul.main-menu.main-menu li > a{
	// 		color: $colour;

	// 		@media (max-width: $b2){
	// 			color: white;
	// 		}
	// 	}
	// }

	// .scroll-down &{
	// 	transform: translateY(-100%);
	// 	pointer-events: none;
	// }

	// .scroll-up &{
	// 	transform: translateY(0%);
	// 	background-color: white;
	// 	box-shadow: 0 10px 15px rgba(black, .1);

	// 	@media (min-width: $b2 + 1){
			
	// 		#site-logo{
	// 			margin-top: 30.89px;
	// 			margin-bottom: 30.89px;
	// 			width: 79px;
	// 			overflow: hidden;

	// 			img{
	// 				max-width: 280px;
	// 				width: 280px;
	// 			}
	// 		}
	// 	}
	// }
}

html:not(.menu-open) .scrolled.scrolled.scrolled{

	@media (min-width: $b2 + 1){
		
		#site-logo{
			margin-top: 22.54px;
			margin-bottom: 22.54px;
		}
	}

	.menu-top-outer{
		background-color: $grey;
	}
}

#site-logo{
	display: block;
	height: auto;
	max-width: 100%;
	position: relative;
	margin: 0;
	transition: $time;
	z-index: 1;
	width: 153.4343px;
	z-index: 50;
	transition: 0s;

	@media (max-width: $b2){
		width: 100px;

		.menu-open &{
			z-index: 112;
		}
	}

	img{
		display: block;
		width: 100%;
	}
}

#side-logo{
	display: block;
	height: auto;
	max-width: 100%;
	position: relative;
	margin: 0;
	transition: $time;
	z-index: 1;
	width: 90px;
	z-index: 50;
	transition: 0s;

	@media (max-width: $b2){
		width: 52px;
		margin: 0 0 0 auto;

		.menu-open &{
			z-index: 112;
		}
	}

	img{
		display: block;
		width: 100%;
	}
}

html:not(.menu-open) .scrolled,
html:not(.menu-open) .light{

	#site-logo{

		svg{

			*{
				fill: $white;
			}
		}
	}

	.social-menu a{
		color: white;
	}
}

.menu-top-outer{
	background-color: $orange;
	padding-top: 7.51px;
	padding-bottom: 7.51px;

	.col-12{
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: $b2){
			align-items: center;
		}
	}

	a{
		color: white;

		*{
			color: white;
		}

		&:hover,
		&:focus{
			color: $grey-darker;

			*{
				color: $grey-darker;
			}
		}
	}

	.social-menu{
		
		a{
			color: rgba(white, .7);
	
			*{
				color: rgba(white, .7);
			}
	
			&:hover,
			&:focus{
				color: $grey-darker;
	
				*{
					color: $grey-darker;
				}
			}
		}
	}

	.contact{
		display: flex;
		align-items: center;

		@media (max-width: $b2){
			flex-wrap: wrap;
		}
	}

	.account,
	.sing-out,
	.basket,
	.mobile,
	.email{
		padding-left: 28px;
		@include font-size(16);
		background-repeat: no-repeat;
		background-position: 0 55%;
		margin-right: 20px;
	}

	.account,
	.sing-out,
	.basket,
	.mobile{
		position: relative;
		margin-right: 43px;

		@media (max-width: $b2){
			margin-right: 15px;
		}

		&:after{
			content: '';
			position: absolute;
			top: -1px;
			bottom: -1px;
			right: -22px;
			width: 1px;
			background-color: white;

			@media (max-width: $b2){
				display: none;
			}
		}
	}

	.account{
		background-image: url(../images/account.svg);

		@media (max-width: $b2){
			@include font-size(0);
			padding-left: 16px;
		}
	}

	.sing-out{
		padding-left: 0;
	}

	.basket{
		position: relative;
		background-image: url(../images/basket.svg);

		@media (max-width: $b2){
			@include font-size(0);
			padding-left: 17px;
		}

		.cart-contents-count{
			@include font-size(12);
			line-height: 15px;
			border-radius: 16px;
			background-color: $grey-darker;
			color: white;
			width: 16px;
			max-width: 16px;
			height: 16px;
			padding-bottom: 1px;
			display: inline-block;
			text-align: center;
			font-family: $alt-font;
			margin-left: 3px;
			transform: translateY(-5px);

			@media (max-width: $b3){
				transform: translateY(-2px);
				margin-left: -5px;
			}
		}

		&:hover,
		&:focus{
			
			.cart-contents-count{
				color: $white;
			}
		}
	}

	.mobile{
		background-image: url(../images/mobile.svg);
		
		@media (max-width: $b2){
			@include font-size(0);
			padding-left: 10px;
		}
	}

	.email{
		background-image: url(../images/email.svg);
		margin-right: 0;

		@media (max-width: $b2){
			@include font-size(0);
			margin-right: 0;
			padding-left: 16px;
		}

	}
	
}

.menu-middle-outer{

	@media (max-width: $b2){
		padding-top: 7px;
		padding-bottom: 7px;
	}

	.col-12{
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: $b2){
			align-items: center;
		}
	}
}

.menu-bottom-outer{
	background-color: $grey-darker;

	.col-12{
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.mob-only.mob-only{

	@media (min-width: 1300px + 1 ){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	display: flex;
	align-items: center;
	position: relative;

	.hide-menu &{
		display: none;
	}

	@media (max-width: $b2){
		overflow: auto;
		height: 100vh;
		height: calc(100vh - 39.11px);
		position: fixed;
		top: 39.11px;
		width: 100%;
		background-color: $white;
		left: 0;
		right: 0;
		display: none;
		z-index: 111;
		transition: $time;
		padding: 72.38px 9.5px 20px;
		text-align: center;

		&:before{
			content: '';
			position: fixed;
			top: 39.11px;
			left: 0;
			right: 0;
			height: 72.38px;
			background-color: white;
		}

		.menu-open &{
			display: block;
		}
	}
}

#mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
#mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
#mainMenu .main-menu .current-menu-parent,
#mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
#mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom){

	> a{
		color: $orange;
	}
}

#secondaryMenu .secondary-menu .current-menu-ancestor:not(.menu-item-object-custom),
#secondaryMenu .secondary-menu .current-page-ancestor:not(.menu-item-object-custom),
#secondaryMenu .secondary-menu .current-menu-parent,
#secondaryMenu .secondary-menu .current_page_parent:not(.menu-item-object-custom),
#secondaryMenu .secondary-menu .current-menu-item:not(.menu-item-object-custom){

	> a{
		color: $orange;
	}
}

ul.main-menu.main-menu{
	margin: 0;
	padding: 0;
	@include font-size(0);
	display: flex;
	justify-content: center;
	position: relative;
	margin-right: 19px;

	@media (max-width: 1200px){
		margin-right: 0;
	}

	@media (max-width: $b2){
		flex-direction: column;
		position: static;
		margin-bottom: 0;
		overflow: hidden;
		margin-right: 0;
	}

	li{
		@include font-size(0);
		line-height: 1em;
		text-align: left;

		@media (min-width: $b2 + 1){

			// &:first-of-type{

			// 	> a{
			// 		padding-right: 0;
			// 	}
			// }

			// &:last-of-type{

			// 	> a{
			// 		padding-left: 0;
			// 	}
			// }

			&:hover{

				> a{
					color: $orange;
				}
			}
		}

		&.menu-item-has-children{

			@media (min-width: $b2 + 1){

				> a{
					padding-right: 35px;

					@media (max-width: 1200px){
				
						@media (min-width: $b2 + 1){
							padding-right: 23px;
						}
					}

					&:after{
						content: '';
						position: absolute;
						top: 50%;
						right: 19px;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 5px 4px 0 4px;
						border-color: $grey-dark transparent transparent transparent;
						transform: translateY(-50%);

						@media (max-width: 1200px){
				
							@media (min-width: $b2 + 1){
								right: 11px;
							}
						}
					}
				}

				&:hover{

					> a{
						background-color: $white;
						color: $orange;
					}

					> ul{
						opacity: 1;
						visibility: visible;
						transition: $time;
						pointer-events: auto;
						z-index: 3;
					}
				}
			}

			@media (max-width: $b2){
				padding-right: 50px;
				background-repeat: no-repeat;
				background-position: right 19px top 16px;
				position: relative;

				&:after,
				&:before{
					content: '';
					position: absolute;
					top: 22px;
					right: 4px;
					left: auto;
					transform: translateY(-50%) rotate(0deg);
					width: 24px;
					height: 2px;
					background-color: $grey-darker;
					transition: $time;
					display: block;
				}

				&:before{
					transform: translateY(-50%) rotate(-90deg);
				}

				&.open{

					&:before{
						transform: translateY(-50%) rotate(0deg);
					}

					.sub-menu{
						pointer-events: auto!important;
					}

					> .sub-menu{
						max-height: 1000px;
						position: relative;
						pointer-events: auto;

						&:after{
							content: '';
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							max-width: 100%;
							opacity: .4;
							visibility: visible;
						}
					}
				}
			}

			.sub-menu{
				position: absolute;
				top: 100%;
				left: 0;
				width: 250px;
				max-width: 250px;
				background-color: $white;
				opacity: 0;
				visibility: hidden;
				margin: 0;
				padding-top: 0px;
				padding-bottom: 0px;
				pointer-events: none;

				@media (max-width: $b2){
					position: static;
					opacity: 1;
					visibility: visible;
					background-color: transparent;
					transform: none;
					width: 100%;
					max-width: 100%;
					max-height: 0;
					overflow: hidden;
					padding-top: 0px;
					padding-bottom: 0px;
				}

				li{
					margin: 0;
					padding: 0;

					@media (max-width: $b2){
						padding: 0;

						&.open{

							.sub-menu{
								pointer-events: auto!important;
							}

							.sub-menu:after{
								display: none;
							}
						}
					}

					> a{

						@media (min-width: $b2 + 1){

							&:hover{
								color: $orange;
							}
						}
					}

					a{
						background-color: transparent;
						display: block;
						text-align: left;
						color: $grey-darker;
						padding: 7px 17px;
						@include font-size(16);
						text-shadow: none!important;
						min-width: 0;

						@media (max-width: $b2){
							padding: 10px 0 10px 10px;
							@include font-size(16);
							color: $grey-darker;
							text-transform: none;

							&:after{
								display: none;
							}

						}
					}
				}
			}
		}

		> a{
			@include font-size(20);
			letter-spacing: .02em;
			width: auto;
			color: $grey-darker;
			line-height: 1.5em;
			text-decoration: none!important;
			font-weight: 600;
			position: relative;
			font-family: $alt-font;
			display: inline-block;
			position: relative;
			padding: 46px 17px;
			overflow: hidden;
			text-transform: uppercase;
			z-index: 2;

			@media (max-width: 1200px){
				
				@media (min-width: $b2 + 1){
					@include font-size(17);
					padding-left: 10px;
					padding-right: 10px;
				}
			}

			@media (min-width: $b2 + 1){

				&:hover,
				&:focus{
					color: $orange;
				}
			}

			@media (max-width: $b2){
				@include font-size(16);
				padding: 10px 0;
			}
		}
	}
}


ul.secondary-menu.secondary-menu{
	margin: 0;
	padding: 0;
	@include font-size(0);
	display: flex;
	justify-content: center;
	position: relative;

	@media (min-width: $b2 + 1){
		
		&.mob-only{
			display: none;
		}
	}

	@media (max-width: $b2){
		flex-direction: column;
		position: static;
		margin-bottom: 0;
		overflow: hidden;
		margin-right: 0;

		&.desk-only{
			display: none;
		}
	}

	li{
		@include font-size(0);
		line-height: 1em;
		text-align: left;

		@media (min-width: $b2 + 1){

			&:last-of-type{

				a{

					&:before{
						display: none;
					}
				}
			}

			&:hover{

				> a{
					color: $orange;
				}
			}
		}

		> a{
			@include font-size(19);
			letter-spacing: .035em;
			width: auto;
			color: white;
			line-height: 1.5em;
			text-decoration: none!important;
			font-weight: 600;
			position: relative;
			font-family: $alt-font;
			display: inline-block;
			position: relative;
			padding: 13px 43px;
			overflow: hidden;
			text-transform: uppercase;
			z-index: 2;

			@media (max-width: 1200px){
				
				@media (min-width: $b2 + 1){
					@include font-size(17);
					padding-left: 32px;
					padding-right: 32px;
				}
			}

			@media (min-width: $b2 + 1){

				&:before{
					content: '';
					position: absolute;
					top: 21px;
					bottom: 18px;
					right: 0px;
					transform: translateX(50%);
					width: 1px;
					background-color: white;
				}

				&:hover,
				&:focus{
					color: $orange;
				}
			}

			@media (max-width: $b2){
				@include font-size(16);
				padding: 10px 0;
				color: $grey-darker;
			}
		}
	}
}