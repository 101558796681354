footer{
	position: relative;
	background-color: $grey-darker;
	background-position: 50% 0;

	.container{
		max-width: 1371px;
	}

	.footer-top-outer{
		padding: 61px 0 64px;
		text-align: center;

		@media (max-width: $b3){
			padding: 50px 0 0;
		}

		*{
			color: white;
		}

		h4{
			@include font-size(26);
			@include line-height(26,32);
			letter-spacing: 0;
			margin-bottom: 1.25em;
		}

		.col-md-6{
			
			@media (min-width: $b3 + 1){
				flex: 0 0 43.4%;
				max-width: 43.4%;
			}
		}

		.col-md-3{

			@media (min-width: $b3 + 1){
				flex: 0 0 28.3%;
				max-width: 28.3%;
			}

			&:nth-of-type(1){
				border-right: 1px solid rgba($grey, .2);
			}

			&:nth-of-type(3){
				border-left: 1px solid rgba($grey, .2);
				position: relative;
				padding-bottom: 30px;

				.mobile,
				.email{
					margin-bottom: .55em;
				}

				.btt{
					@include font-size(14);
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					background-image: url(../images/arrow-up.svg);
					background-repeat: no-repeat;
					padding-left: 40px;
					background-position: 0 50%;
					text-transform: uppercase;
					font-weight: 500;
					letter-spacing: .03em;

					@media (max-width: $b3){
						position: static;
						margin: 1em auto 0;
						display: inline-block;
						transform: none;
					}
				}
			}
		}

		a{
			@include font-size(18);
			@include line-height(18,30);
			color: white;
			display: inline-block;

			span{
				color: rgba(white, .7);
			}

			&:hover,
			&:focus{
				color: $orange;
			}

			@media (max-width: $b3){
				@include font-size(18);
				@include line-height(18,21);
				margin-bottom: 1.2em;
			}
		}

		.social-menu{
			padding-top: 10px;
			margin-bottom: 1.35em;
			justify-content: center;

			a{
				width: 35px;
				height: 35px;
				background-color: #7A7A7A;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 13px;
				border-radius: 35px;

				&:hover,
				&:focus{
					background-color: $orange;
				}
			}
		}

		.logos{
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 2.3em;

			.logo{
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 25px;
			}
		}
		
		.footer-menu{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0 auto;
			@include font-size(16);
			line-height: 1.125;

			@media (max-width: $b3){
				width: 100%;
				justify-content: flex-start;
				margin-top: 28px;
			}


			li{
				@include font-size(18);
				@include line-height(18,30);
				width: 100%;

				a{
					@include font-size(18);
					@include line-height(18,30);
					font-weight: 400;
					text-decoration: none;
					color: $white;
					display: block;

					@media (max-width: $b3){
						margin-bottom: 8px;
						line-height: 1.5;
					}
					
					&:hover,
					&:focus{
						color: $orange!important;
						text-decoration:none;
					}
				}
			}
		}

		.copy{
			@include font-size(16);
			@include line-height(16,30);
			margin: 0 auto 1em;
			color: white;

			@media (max-width: $b3){
				margin-bottom: 1.6em;
				text-align: center;
			}
		}
	}
}
