  //!!FORM
::-webkit-input-placeholder {
	@include font-size(19);
	color: $grey-dark;
}
 
:-moz-placeholder { /* Firefox 18- */
	@include font-size(19);
	color: $grey-dark;
}

::-moz-placeholder {  /* Firefox 19+ */
	@include font-size(19);
	color: $grey-dark;
}

:-ms-input-placeholder {
	@include font-size(19);
	color: $grey-dark;
}

form{
	margin: 0 auto;
	width: auto;
	display: block;
	margin-bottom: 0;

	&:after{
		visibility:hidden;
		display:block;
		font-size:0;
		content:" ";
		clear:both;
		height:0;
	}

	* html &{
		height:1%;
	}
}

$input-bottom-margin: 19px;
$label-bottom-margin: 19px;

input,
input.input-text,
textarea,
select{
	display: block;
	padding: 10px 17px;
	@include font-size(19);
	font-weight: 400;
	color: $grey-dark;
	font-family: $main-font;
	width: 100%;
	max-width: 100%;
	margin-bottom: $input-bottom-margin;
	border: 1px solid $white;
	background-color: $white;
	border-radius: 3.329px;
	line-height: 19px;
	-webkit-appearance: none;
	box-shadow: none;

	@media (max-width: $b3){
		margin-bottom: 19px;
	}
}

textarea{
	height: 205px;
	min-height: 205px;
	min-width: 100%;

	@media (max-width: $b3){
		height: 130px;
		min-height: 130px;
	}
}

input:focus,
input.input-text:focus,
textarea:focus,
select:focus{
	outline: none;
}

input:focus,
input.input-text:focus,
textarea:focus,
select:focus{
	outline: none;
	transition: color 0.5s ease, background-color 0.5s ease;
}

[type="file"]{
	border: none;
	padding: 0;
}

//
//!!SELECT START
//
select{
	padding-right: 32px;
	appearance: none;
	@include svg_img("../images/select-icon");
	background-position: 100% 50%;
	line-height: 45px;
	height: 47px;
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;

	&::-ms-expand {
		display: none;
	}

	option{
		padding-top: 5px;
		padding-bottom: 5px;
		color: $colour;
	}
}
//
//!!SELECT END
//

label{
	display: block;
	@include font-size(16);
	font-family: $main-font;
	margin-bottom: $label-bottom-margin;
	transition: $time;
	font-weight: 700;
	color: $colour;
	text-transform: uppercase;

	@media (max-width: $b3){
		@include font-size(14);
	}

	.gfield_required{
		display: none;
	}

	+ .small{
		margin-top: -21px;
		margin-bottom: 1.655em;
	}
}

*[type="submit"],
.submit-con [type="submit"]{
	@extend .btn;
	margin-bottom: 0;
	margin-top: 18px;
	width: auto;
	cursor: pointer;
}

.submit-con{
	display: flex;
	justify-content: flex-start;

}

//
//!!RADIO & CHECKBOX START
//

.input-radio,
.input-checkbox{
	position: relative;
	@include inline;
	margin-bottom: 5px;

	&:before{
		content: '';
		display: block;
		position: absolute;
	}
}

input[type="radio"],
input[type="checkbox"]{
	display: none;

	& + span,
	& + .wpcf7-list-item-label,
	& + label{
		padding-left: 26px!important;
		position: relative;
		cursor: pointer;
		@include font-size(19);
		line-height: 21px;
		display: inline-flex;
		flex-wrap: wrap;
		margin-bottom: 5px;
		font-style: normal;
		color: $colour;
		font-weight: 400;
		text-transform: none;
		
		img{
			margin-left: 1em;
		}

		@media (max-width: $b3){
			@include font-size(16);
		}

		a{
			color: $colour;
			font-weight: 700;
			text-decoration: underline;

			&:hover,
			&:focus{
				color: $orange;
			}
		}

		&:before{
			position: absolute;
			content: "";
			width: 21px;
			height: 21px;
			left: 0;
			top: 2px;
			border-radius: 0px;
			background-position: 0% 50%;
			border: 1px solid $white;
			background-color: $white;
			font-weight: 400;
		}

		&:after{
			position: absolute;
			content: "";
			width: 15px;
			height: 15px;
			line-height: 29px;
			text-align: center;
			font-weight: 500;
			left: 3px;
			top: 3px;
			top: 5px;
			background-color: $orange;
			opacity: 0;
			transition: $time;
			border-radius: 0;
		}
	}

	&:checked + label:after,
	&:checked + .wpcf7-list-item-label:after,
	&:checked + span:after,
	& + label.checked:after{
		opacity: 1;
	}
}

input[type="radio"]{

	& + .wpcf7-list-item-label,
	& + label{

		&:before,
		&:after{
			border-radius: 18px;
		}
	}
}

//
//!!RADIO & CHECKBOX END
//


//
//!!CONTACT FORM 7 START
//
.send-con{
	display: flex;
	justify-content: flex-end;
	padding: 3px 0 0;
}

.wpcf7{
	position: relative;

	.col{
		position: static;
	}

	h3{
		width: 100%;
	}

	.your-consent{
		padding-top: 10px;
	}

	form{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 1px 0 1px;

		[type="submit"]{

			@media (max-width: $b3){
				margin-top: 20px;
			}

			&.space-above{
				margin-top: 76px;

				@media (max-width: $b3){
					margin-top: -11px;
				}
			}
		}

		.field-p{
			margin-bottom: 0;
		}

		&.sent{

			.wpcf7-list-item{

				label{
					opacity: 1;
				}
			}

			label,
			.wpcf7-form-control-wrap,
			input[type="submit"],
			button,
			.num-button{
				pointer-events: none;
				opacity: .2;
				transition: .4s;
			}
		}

		label{

			span{
				color: $red;
			}
		}

		.wpcf7-form-control-wrap{
			display: block;
			width: 100%;
		}

		> div{
			width: 100%;
		}

		.form-text{
			display: flex;
			flex-direction: column;
			text-align: center;
			align-items: center;
		}

		.small,
		.medium,
		.large{
			position: relative;
		}

		.small{
			width: 32%;
			width: calc(33.333% - 13px);
			width: calc(33.333% - (#{$grid-gutter-width}/2));

			@media (max-width: $b3){
				width: calc(50% - (#{$grid-gutter-width}/2));

				+ .small + .small{
					width: 100%;
				}
			}

			@media (max-width: $b4){
				width: 100%;
			}
		}

		.medium{
			width: calc(50% - (#{$grid-gutter-width}/2));

			@media (max-width: $b3){
				width: 100%;
			}
		}

		.large{
			width: 100%;
		}

		.has-label{
			display: flex;
			align-items: baseline;

			label{
				width: 100px;
			}

			.wpcf7-form-control-wrap{
				width: calc(100% - 100px);
			}
		}

		.btn-con{
			position: static;
			justify-content: center;
		}

		span.wpcf7-not-valid-tip{
			position: absolute;
			background-color: transparent;
			color: $red!important;
			@include font-size(12);
			font-weight: 400;
			line-height: 14px;
			width: auto;
			bottom: -14px;
			transition: $time;
			left: 0px;
			display: none;

			@media (max-width: $b1){
				@include font-size(10);
				bottom: -12px;
			}
		}

		.wpcf7-checkbox,
		.wpcf7-radio{
			display: flex;
			flex-wrap: wrap;
			margin-bottom: $input-bottom-margin/2;

			label{
				margin-bottom: $label-bottom-margin;
			}

			.wpcf7-list-item-label{
				margin-right: 32px;
				margin-right: $grid-gutter-width;
			}
		}

		.wpcf7-list-item{
			display: block;
			margin: 0;

			label{
				margin-bottom: $input-bottom-margin/2;
				text-align: left;
			}
		}
	}

	.wpcf7-spinner{
		background-color: $orange;
		display: none!important;
	}

	.wpcf7-form.submitting{
		position: relative;

		[type="submit"],
		.wpcf7-form-control-wrap{
			pointer-events: none;
			opacity: .2;
		}

		&:before{
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(0deg);
			width: 20px;
			height: 20px;
			animation-name: loading-rotate;
			animation-iteration-count: infinite;
			animation-duration: 3.6s;
			transform-origin: 50% 50%;
			animation-timing-function: linear;
			border: 2px solid $orange;
			border-radius: 0 10px 10px 10px;
			border-width: 1px;
			z-index: 1;

			@keyframes loading-rotate{
				0%,
				100%{
					transform: translate(-50%, -50%) rotate(0deg);
					border-width: 1px;
				}

				99.999%{
					transform: translate(-50%, -50%) rotate(359.999deg);
					border-width: 10px;
				}
			}
		}
	}

	div.wpcf7-response-output.wpcf7-response-output.wpcf7-response-output,
	div.wpcf7-mail-sent-ok,
	div.wpcf7-validation-errors,
	div.wpcf7-acceptance-missing{
		margin: 1.5em 0 .5em;
		border: 1px solid $red;
		background-color: white;
		color: $red;
		text-align: center;
		@include font-size(14);
		line-height: 1.4em;
		padding: 5px 5px;
		border-radius: 3.329px;
		font-weight: 400;
	}

	.sent{

		div.wpcf7-response-output.wpcf7-response-output.wpcf7-response-output{
			color: $green;
			border-color: $green;
		}
	}

	div.wpcf7-mail-sent-ok{
		background-color: $green;
		border-color: $green;
	}

	.LV_validation_message{
		display: none;
	}
}

.re ~ .res{
	display: none;
}

//
//!!CONTACT FORM 7 END
//
