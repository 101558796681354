//!!BUTTON
button:focus,
.btn:focus{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -10px;
	max-width: calc(100% + 10px + 10px);
	flex-wrap: wrap;

	@media (max-width: $b2){
		max-width: calc(100% + 7px + 7px);
		margin: 0 -7px;
	}

	.btn{
		margin: 0 10px 5px;

		@media (max-width: $b2){
			margin: 0 7px 5px;
		}
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
}

.btn{
	display: inline-flex;
	align-items: center;
	padding: 16px 27px;
	font-size: 17px!important;
	font-size: 1.7rem!important;
	text-decoration: none!important;
	line-height: 1.5em;
	letter-spacing: .065em;
	border: none;
	color: $white!important;
	font-weight: 600;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	box-shadow: none;
	font-family: $alt-font;
	position: relative;
	border-radius: 3.7px;
	transition: $time;
	background-color: $orange;
	min-width: 109px;
	text-transform: uppercase;
	justify-content: center;

	&:hover,
	&:focus{
		outline: none;
		background-color: $grey-darker;
		border-color: $grey-darker;
		color: $white!important;
	}

	&.arrow{
		background-image: url(../images/arrow-right.svg);
		background-repeat: no-repeat;
		background-position: right 29px top 50%;
		padding-right: 52px;
	}

	&.grey{
		background-color: $grey-darker;
		border-color: $grey-darker;
		color: $white!important;

		&:hover,
		&:focus{
			background-color: $orange;
			border-color: $orange;
			color: $white!important;
		}
	}

	&:disabled{
		opacity: .5;

		&:hover,
		&:focus{
			background-color: $orange;
			border-color: $orange;
			color: $white!important;
		}
	}

	&.big{
		padding: 14.5px 18px;
		font-size: 20px!important;
		font-size: 2.0rem!important;

		@media (max-width: $b3){
			font-size: 15px!important;
			font-size: 1.5rem!important;
			padding: 18px 28px;
		}
	}

	&.small{
		min-width: 103px;
		padding: 9.5px 27px;
	}
}